import { ReactComponent as ChumbiLogoBig } from 'assets/chumbi-logo-big.svg';
import { ReactComponent as DiscordLogo } from 'assets/discord-logo.svg';
import { ReactComponent as FacebookLogo } from 'assets/facebook-logo.svg';
import { ReactComponent as InstagramLogo } from 'assets/instagram-logo.svg';
import { ReactComponent as OpenSeaLogoBlue } from 'assets/opensea-blue-wordmark.svg';
import { ReactComponent as TelegramLogo } from 'assets/telegram-logo.svg';
import { ReactComponent as TwitterLogo } from 'assets/twitter-logo.svg';
import TreeNumFour from 'assets/tree-num-four.png';
import TreeNumOne from 'assets/tree-num-one.png';
import TreeNumThree from 'assets/tree-num-three.png';
import TreeNumTwo from 'assets/tree-num-two.png';

// styles
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.footerOuterDiv} id="subscribe">
      <div className={styles.footerInnerDiv}>
        <div className={styles.treeSetOne}>
          <img src={TreeNumOne} alt="tree" className={styles.treeNumOne} />
          <img src={TreeNumTwo} alt="tree" className={styles.treeNumTwo} />
        </div>
        <div className={styles.footerContent}>
          <div className={styles.footerChumbiLogo}>
            <ChumbiLogoBig className={styles.chumbiLogoBig} />
          </div>
          <div className={styles.inputAndSubscribeBtn}>
            <form
                action="https://static.mailerlite.com/webforms/submit/v5s5u9"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
                data-code="v5s5u9"
            >
              <input
                className={styles.inputEmail}
                placeholder="Email"
                type="text"
                name="EMAIL"
                id="mce-EMAIL"
              />
              <button
                className={styles.subscribeBtn}
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe">
                Subscribe
              </button>
            </form>
          </div>
          <div className={styles.socialsMediaIcons}>
            <a href="https://discord.com/invite/chumbi" rel="noreferrer" target="_blank">
              <button className={styles.mediaIconsBtn}>
                <DiscordLogo className={styles.mediaIconsSvg} />
              </button>
            </a>
            <a href="https://t.me/chumbivalley01" rel="noreferrer" target="_blank">
              <button className={styles.mediaIconsBtn}>
                <TelegramLogo className={styles.mediaIconsSvg} />
              </button>
            </a>
            <a href="https://twitter.com/ChumbiValley" rel="noreferrer" target="_blank">
              <button className={styles.mediaIconsBtn}>
                <TwitterLogo className={styles.mediaIconsSvg} />
              </button>
            </a>
            <a href="https://www.instagram.com/ChumbiValley" rel="noreferrer" target="_blank">
              <button className={styles.mediaIconsBtn}>
                <InstagramLogo className={styles.mediaIconsSvg} />
              </button>
            </a>
            <a href="https://www.facebook.com/chumbivalley" rel="noreferrer" target="_blank">
              <button className={styles.mediaIconsBtn}>
                <FacebookLogo className={styles.mediaIconsSvg} />
              </button>
            </a>
            <a
              href="https://opensea.io/collection/chumbi-valley-founders-collection"
              rel="noreferrer"
              target="_blank">
              <button className={styles.mediaIconsBtn}>
                <OpenSeaLogoBlue className={styles.mediaIconsSvg} />
              </button>
            </a>
          </div>
          <div className={styles.chumbiInfoTextDiv}>
            <a
              href={process.env.REACT_APP_MAIN_URL + '/chumbi-valley-privacy-policy.pdf'}
              rel="noreferrer"
              target="_blank">
              <p className={styles.chumbiInfoTextNew}>Privacy Policy</p>
            </a>
            <p className={styles.chumbiInfoTextNew} style={{ margin: '0 5px' }}>
              |
            </p>
            <a
              href={process.env.REACT_APP_MAIN_URL + '/chumbi-valley-website-terms-and-conditions.pdf'}
              rel="noreferrer"
              target="_blank">
              <p className={styles.chumbiInfoTextNew}>Terms & Conditions</p>
            </a>
          </div>
        </div>
        <div className={styles.treeSetTwo}>
          <img src={TreeNumThree} alt="tree" className={styles.treeNumThree} />
          <img src={TreeNumFour} alt="tree" className={styles.treeNumFour} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
