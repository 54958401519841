// assets
import metamaskLogo from '../../assets/metamask.png';
// context
import { useWallet } from 'contexts/Wallet';
// styles
import styles from './MetamaskAuth.module.scss';

export default function MetamaskAuth() {
  const { connect } = useWallet();

  const connectWallet = () => {
    connect();
  };

  return (
    <div className={styles.outerMetamaskAuth}>
      <main className={styles.content}>
        <div className={styles.overlayDetails}>
          <img src={metamaskLogo} alt="metamask logo" height="129px" />
          <button className={styles.connectBtn} onClick={connectWallet}>
            Connect Wallet
          </button>
        </div>
      </main>
    </div>
  );
}
