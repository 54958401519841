export const getDate = (D: Date) => {
  return D.toString()
    .split(' ')
    .splice(1, 3)
    .reduce((a, b) => a + ' ' + b, '')
    .slice(1);
};

export const numberWithCommas = (x: number|string) => {
  const num_1 = x.toString().split('.')[0];
  const num_2 = x.toString().split('.')[1] ? '.' + x.toString().split('.')[1] : '';
  const formatNum = num_1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return (formatNum + num_2);
};

export function DaysBetween(StartDate: number, EndDate: number) {
  const oneDay = 1000 * 60 * 60 * 24;

  const start = Date.UTC(
    new Date(EndDate).getFullYear(),
    new Date(EndDate).getMonth(),
    new Date(EndDate).getDate(),
  );
  const end = Date.UTC(
    new Date(StartDate).getFullYear(),
    new Date(StartDate).getMonth(),
    new Date(StartDate).getDate(),
  );

  return (start - end) / oneDay;
}
