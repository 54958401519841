import { Fragment, useEffect, useState } from 'react';
// libraries
// import { useLocation } from 'react-router';
// import { Link } from 'react-router-dom';
import Hamburger from 'hamburger-react';

// assets
import { ReactComponent as ChumbiLogo } from 'assets/chumbi-logo.svg';
import { ReactComponent as ConnectWalletIcon } from 'assets/connect-wallet.svg';
import { ReactComponent as BscIcon } from 'assets/bsc-icon.svg';
// import { useAvailableChumbi } from '../context/chumbiNFT';
// context
import { useWallet } from 'contexts/Wallet';
// styles
import styles from './HeaderTransparent.module.scss';
import { toast } from 'react-toastify';
import useSwitch from 'hooks/useSwitch';

const navLinks: string[] = ['Connect Wallet'];

const hrefLinks: string[] = [''];

const HeaderTransparent = () => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const connectedToAllowedNetwork = useSwitch(true);

  const checkConnection = async () => {
    const _bool = await isConnectedToAllowedNetwork();
    connectedToAllowedNetwork.set(_bool);
  };

  const { connect, disconnect, account, isConnectedToAllowedNetwork, refresh } = useWallet();

  useEffect(() => {
    checkConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh.triggerValue]);

  async function switchNetwork() {
    if (!(window as any).ethereum) return;
    try {
      (window as any).ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${Number(process.env.REACT_APP_BSC_CHAIN_ID).toString(16)}`,
            chainName: `${process.env.REACT_APP_BSC_CHAIN_NAME}`,
            nativeCurrency: {
              name: `${process.env.REACT_APP_NAME}`,
              symbol: `${process.env.REACT_APP_BSC_SYMBOL}`,
              decimals: 18,
            },
            rpcUrls: [`${process.env.REACT_APP_BSC_RPC_URL}`],
            blockExplorerUrls: [`${process.env.REACT_APP_BSC_BLOCK_EXPLORER_URLS}`],
          },
        ],
      });
    } catch (e) {
      toast.error(`Please switch to the ${process.env.REACT_APP_BSC_CHAIN_NAME}`);
    }
  }

  return (
    <div className={styles.headerTransparentOuterDiv}>
      <header>
        <a href={`${process.env.REACT_APP_MAIN_URL}`}>
          <div className={styles.chumbiLogoDiv}>
            <ChumbiLogo className={styles.chumbiLogoSvg} />
          </div>
        </a>
        {isOpen !== true ? (
          <ul className={styles.navLinksDiv}>
            {navLinks.map((navLink, index) => (
              <Fragment key={index.toString()}>
                {index === 0 ? (
                  <>
                    {account && !connectedToAllowedNetwork.value ? (
                      <button
                        key={index}
                        className={styles.connectWalletBtn + ' ' + styles.shakeAnimation}
                        onClick={() => {
                          switchNetwork();
                        }}
                        style={{ backgroundColor: '#f3ba2f' }}>
                        <BscIcon className={styles.connectWalletIcon} style={{ marginRight: '10px' }} />
                        Switch to BSC
                      </button>
                    ) : (
                      <button
                        key={index}
                        className={styles.connectWalletBtn}
                        onClick={async () => {
                          account ? disconnect() : connect();
                        }}>
                        <ConnectWalletIcon
                          className={styles.connectWalletIcon}
                          style={{ marginRight: '10px' }}
                        />
                        {account ? account.substring(0, 8) + '....' : navLink}
                      </button>
                    )}
                  </>
                ) : (
                  <a
                    key={index}
                    href={
                      index === 0
                        ? '/'
                        : navLink === 'Litepaper'
                        ? `${process.env.REACT_APP_MAIN_URL}/chumbi-valley_litepaper_v1.3.pdf`
                        : `${process.env.REACT_APP_MAIN_URL}#${hrefLinks[index]}`
                    }
                    target="_blank"
                    rel="noopener noreferrer">
                    <li className={styles.navLink}>{navLink}</li>
                  </a>
                )}
              </Fragment>
            ))}
          </ul>
        ) : (
          ''
        )}
        <div className={styles.hamburgerDiv}>
          <Hamburger color="rgb(102, 166, 92)" toggled={isOpen} toggle={setOpen} rounded />
        </div>
      </header>
      {isOpen === true ? (
        <ul className={styles.navLinksDropdown}>
          {navLinks.map((navLink, index) => (
            <Fragment key={index.toString()}>
              {index === 0 ? (
                <>
                  {account && !connectedToAllowedNetwork.value ? (
                    <button
                      key={index}
                      className={styles.connectWalletBtn + ' ' + styles.shakeAnimation}
                      onClick={() => {
                        switchNetwork();
                      }}
                      style={{ backgroundColor: '#f3ba2f' }}>
                      <BscIcon className={styles.connectWalletIcon} style={{ marginRight: '10px' }} />
                      Switch to BSC
                    </button>
                  ) : (
                    <button
                      key={index}
                      className={styles.connectWalletBtn}
                      onClick={async () => {
                        account ? disconnect() : connect();
                      }}>
                      <ConnectWalletIcon
                        className={styles.connectWalletIcon}
                        style={{ marginRight: '10px' }}
                      />
                      {account ? account.substring(0, 8) + '....' : navLink}
                    </button>
                  )}
                </>
              ) : (
                <a
                  key={index}
                  href={
                    index === 0
                      ? '/'
                      : navLink === 'Litepaper'
                      ? `${process.env.REACT_APP_MAIN_URL}/chumbi-valley_litepaper_v1.3.pdf`
                      : `${process.env.REACT_APP_MAIN_URL}#${hrefLinks[index]}`
                  }
                  target="_blank"
                  rel="noopener noreferrer">
                  <li className={styles.navLink}>{navLink}</li>
                </a>
              )}
            </Fragment>
          ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  );
};

export default HeaderTransparent;
