import styles from './ChumbiNFTCrateModal.module.scss';
import crateImg from '../../assets/crate-img-new.png';
import blackArrow from '../../assets/black-arrow.png';
import land from '../../assets/land.png';
import genpod from '../../assets/genpod.png';
import merch from '../../assets/merch.png';
import rare from '../../assets/rare.png';
import lantern from '../../assets/lantern.png';
import { useEffect, useState } from 'react';
import MessageModal from 'components/MessageModal';
import { useWallet } from 'contexts/Wallet';
import { PoolDuration } from 'App';

const rareItemsList = [
  { item: 'Land Plot NFT', quant: '16.6%, 500 total ', src: land },
  { item: 'Gen 1 Chumbi NFT', quant: '16.6%, 500 total', src: genpod },
  { item: 'Merch Coupon', quant: '3.33%, 100 total', src: merch },
  { item: 'Seed Chumbi NFT', quant: '0.33%, 10 total', src: rare },
];

type Props = {
  show?: boolean;
  setShow: (active: boolean) => void;
  onClose?: () => void;
  setSelectedPool?: (pool: PoolDuration) => void | undefined;
};

const ChumbiNFTCrateModal = ({ show, onClose, setShow, setSelectedPool }: Props) => {
  const [tokenQuant, setTokenQuant] = useState(0);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [messageModalState, setMessageModalState] = useState<'info' | 'purchase' | 'nothanks'>('info');

  const { purchaseComplete, setTokenAmount, quantityRemaining } = useWallet();

  const [monthPool, setMonthPool] = useState<string | null>(null);

  useEffect(() => {
    if (monthPool === '6' && setSelectedPool) setSelectedPool('180_Days');
    if (monthPool === '12' && setSelectedPool) setSelectedPool('365_Days');
  }, [monthPool, setSelectedPool]);

  useEffect(() => {
    if (tokenQuant > 0) setTokenAmount(tokenQuant);
  }, [setTokenAmount, tokenQuant]);

  useEffect(() => {
    if (purchaseComplete) setMessageModalState('purchase');
  }, [purchaseComplete]);

  const handleTokenDecrement = () => {
    if (tokenQuant > 0) {
      setTokenQuant((prev) => prev - 1);
    }
  };

  const handleTokenIncrement = () => {
    setTokenQuant((prev) => prev + 1);
  };

  const handleBuyBtnClick = () => {
    setIsMessageModalOpen((prev) => !prev);
    setMessageModalState('info');
  };

  const handleNotExclusiveOfferClick = () => {
    setIsMessageModalOpen((prev) => !prev);
    setMessageModalState('nothanks');
  };
  return (
    <>
      {show && (
        <div className={styles.crateModalOuterContainer} onClick={onClose}>
          <div className={styles.crateModalInnerContainer} onClick={(e) => e.stopPropagation()}>
            <img className={styles.blackArrow} src={blackArrow} alt="arrow" />

            <div className={styles.topContainer}>
              <div className={styles.leftDiv}>
                {' '}
                <img className={styles.crateImg} src={crateImg} alt="crate" />{' '}
              </div>
              <div className={styles.rightDiv}>
                <p className={styles.exclusiveText}>EXCLUSIVE OFFER FOR STAKERS:</p>
                <p className={styles.nftCrateText}>Chumbi NFT Crates</p>
                <p className={styles.loyalText}>
                  As a thank you for being a loyal staker, you are eligible for an exclusive Chumbi Valley NFT
                  Crate Offer!
                </p>
                <p className={styles.eligibleText}>
                  To be eligible, you must have staked at least 1,000 CHMB on or before the 14th of March
                </p>
                <div className={styles.infoText}>
                  <p className={styles.darkText}>
                    Quantity: <span className={styles.numberText}>{quantityRemaining}</span>
                  </p>
                  <p className={styles.darkText}>
                    Price: <span className={styles.numberText}>40,000 CHMB (Burnt)</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.middleTopContainer}>
              <div className={styles.leftDiv}>
                <img className={styles.placeholderImg} src={lantern} alt="lantern chumbi" />
              </div>
              <div className={styles.rightDiv}>
                <p className={styles.whatIsChumbiText}>Guaranteed NFT item:</p>

                <p className={styles.voidText}>Lantern of Revealing</p>

                <p className={styles.useChumbiText}>
                  This lantern NFT has in-game utility! Use the lantern to gain access to dark areas of Chumbi
                  Valley that are otherwise locked.
                </p>

                <p
                  className={styles.learnMoreText}
                  onClick={() => window.open(`https://chumbivalley.com/nft-crates`, '_blank')}>
                  Learn More
                </p>
              </div>
            </div>

            <div className={styles.middleBottomContainer}>
              <p className={styles.plusText}>Plus high chance of super rare items:</p>
              <div className={styles.allItemsDiv}>
                {rareItemsList.map(({ item, quant, src }, index) => {
                  return (
                    <div className={styles.singleItemDiv} key={index}>
                      <img src={src} alt="placeholder img" />
                      <p>{item}</p>
                      <p>{quant}</p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={styles.bottomContainer}>
              <div className={styles.bottomInnerContainer}>
                <div className={styles.divOne}>
                  <div className={styles.quantDiv}>
                    <p className={styles.quantityText}>QTY</p>
                    <div className={styles.quantValueDiv}>
                      <button className={styles.minusSign} onClick={handleTokenDecrement}>
                        -
                      </button>
                      <div className={styles.numberText}>
                        <p>{tokenQuant}</p>
                      </div>
                      <button className={styles.plusSign} onClick={handleTokenIncrement}>
                        +
                      </button>
                    </div>
                  </div>

                  <div className={styles.unstakeAndBtn}>
                    <button className={styles.unstakeBtn} onClick={handleBuyBtnClick}>
                      BUY
                    </button>
                    {/* will look in sometime */}
                    <p className={styles.exclusiveOfferText} onClick={handleNotExclusiveOfferClick}>
                      No thanks, I do not want this exclusive offer &gt;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MessageModal
        show={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        setMonthPool={setMonthPool}
        setShowBModal={setShow}
        setShow={setIsMessageModalOpen}
        modalState={messageModalState}
      />
    </>
  );
};

export default ChumbiNFTCrateModal;
