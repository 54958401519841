import { abi as TokenABI } from 'abis/IERC20.json';
import { abi as StakingRewardsABI } from 'abis/StakingRewards.json';
import { abi as LootBoxABI } from 'abis/LootBox.json';
import { IERC20 } from 'types/IERC20';
import { StakingRewards } from 'types/StakingRewards';
import { LootBox } from 'types/LootBox';
import { AllowedChainConfig, ContractConfig } from 'types/config';
import logo from 'assets/Logo.png';

export const APP_CONFIG = {
  COMPANY: {
    NAME: 'ChumbiValley',
    LOGO: logo,
  },
  STAKING_TOKEN: 'CHMB',
  REWARD_TOKEN: 'CHMB',
};

export const allowedChains: AllowedChainConfig[] = [
  {
    id: Number(process.env.REACT_APP_BSC_CHAIN_ID),
    name: `${process.env.REACT_APP_BSC_CHAIN_NAME}`,
  },
];

export const ContractAddress = {
  Token: '0x5492Ef6aEebA1A3896357359eF039a8B11621b45',
  Lootbox: '0xa9DccBB0A53ad349b5F3F3a0f185E0B021995cb8',
  // needs to be deleted
  StakingRewards: '0xcA8102bae3AcDB17Ce29424dc58068E1fFfE127F',
  StakingRewards_90_Days: '0xaf55804D5dDb764fb444e00d99FEc9e52883EE17',
  StakingRewards_180_Days: '0x20112dFceE4C22057aB6751AE1ac2DE0109C468c',
  StakingRewards_365_Days: '0x1568F0E8ff865bE61D2D735AD1Ba07f199A4590a',
};

export const contracts: ContractConfig[] = [
  {
    name: 'Token',
    abi: TokenABI,
    address: ContractAddress.Token,
  },
  {
    name: 'LootBox',
    abi: LootBoxABI,
    address: ContractAddress.Lootbox,
  },
  // needs to be deleted
  {
    name: 'StakingRewards',
    abi: StakingRewardsABI,
    address: ContractAddress.StakingRewards,
  },
  {
    name: 'StakingRewards_90_Days',
    abi: StakingRewardsABI,
    address: ContractAddress.StakingRewards_90_Days,
  },
  {
    name: 'StakingRewards_180_Days',
    abi: StakingRewardsABI,
    address: ContractAddress.StakingRewards_180_Days,
  },
  {
    name: 'StakingRewards_365_Days',
    abi: StakingRewardsABI,
    address: ContractAddress.StakingRewards_365_Days,
  },
];

export interface ContractInstances {
  Token: IERC20;
  LootBox: LootBox;
  // needs to be deleted
  StakingRewards: StakingRewards;
  StakingRewards_90_Days: StakingRewards;
  StakingRewards_180_Days: StakingRewards;
  StakingRewards_365_Days: StakingRewards;
}
