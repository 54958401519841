// hooks
// assets
import { ReactComponent as TokenCoinImg } from 'assets/token-coin-img.svg';
import useWindowSize from 'hooks/useWindowSize';
// styles
import styles from './LaunchDateHeader.module.scss';

const LaunchDateHeader = () => {
  const { width } = useWindowSize();

  return (
    <div className={styles.launchDateDiv}>
      <div className={styles.launchDateHeaderDiv}>
        <TokenCoinImg className={styles.tokenCoinImg} />
        <a
          href="https://bscscan.com/token/0x5492Ef6aEebA1A3896357359eF039a8B11621b45"
          target="_blank"
          rel="noreferrer">
          <p> CHMB : {width >= 900 ? '0x5492Ef6aEebA1A3896357359eF039a8B11621b45' : '0x5492E.....621b45'} </p>
        </a>
        <TokenCoinImg className={styles.tokenCoinImg} />
      </div>
    </div>
  );
};

export default LaunchDateHeader;
