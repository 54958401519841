import classes from 'App.module.scss';

// hooks
import { useState, useEffect, useCallback } from 'react';
import { useWallet } from 'contexts/Wallet';

// libraries
import { toast } from 'react-toastify';

// components
import Card from 'components/Card';
import PoolTabs from 'components/Tabs';
import Spinner from 'components/Spinner';
import LaunchDateHeader from 'components/LaunchDateHeader';
import HeaderTransparent from 'components/HeaderTransparent';
import Footer from 'components/Footer';
import BuyToken from 'components/BuyToken';

// helpers
import Web3 from 'helpers/web3';
import Contracts from 'helpers/contracts';
import { getDate, numberWithCommas } from 'helpers/utils';
import MetamaskAuth from 'components/MetmaskAuth';

export type PoolDuration = '90_Days' | '180_Days' | '365_Days';

function App() {
  const { account, isConnectedToAllowedNetwork, refresh } = useWallet();
  const [stakingCap, setStakingCap] = useState<number>(0);

  const [stakingBalance, setStakingBalance] = useState('0.00');
  const [stakedAmount, setStakedAmount] = useState('0.00');
  const [earnedBalance, setEarnedBalance] = useState('0.00');
  const [stakingPeriod, setStakingPeriod] = useState('0.00');
  const [rewardDate, setRewardDate] = useState<string | number>();
  const [stakingTill, setStakingTill] = useState<string | number>();
  const [totalStaking, setTotalStaking] = useState(0);
  const [isStakingMode, setIsStakingMode] = useState(true);
  const [isStakingWindow, setIsStakingWindow] = useState(true);
  const [selectedPool, setSelectedPool] = useState<PoolDuration>('90_Days');
  const [isLoading, setIsLoading] = useState(false);
  const [stakingAggregate, setStakingAggregate] = useState(0);

  useEffect(() => {
    if (rewardDate && Date.now() > rewardDate) setIsStakingMode(false);
    else setIsStakingMode(true);
  }, [rewardDate]);

  useEffect(() => {
    if (stakingTill && Date.now() > stakingTill) setIsStakingWindow(false);
  }, [stakingTill]);

  const resetUserData = () => {
    setStakingBalance('0.00');
    setStakedAmount('0.00');
    setEarnedBalance('0.00');
  };

  const resetCommonData = () => {
    setRewardDate(getDate(new Date(0)));
    setStakingPeriod('0');
  };

  const fetchUserData = useCallback(async () => {
    if (!(await isConnectedToAllowedNetwork())) return resetUserData();

    const web3 = Web3.instance;
    const contracts = Contracts.instances;

    try {
      setIsLoading(true);
      const [_stakingBalance, _stakedAmount, _earnedBalance] = await Promise.all([
        await web3.utils.fromWei(await contracts.Token.methods.balanceOf(account).call()),
        await web3.utils.fromWei(
          await contracts[`StakingRewards_${selectedPool}`].methods.balanceOf(account).call(),
        ),
        await web3.utils.fromWei(
          await contracts[`StakingRewards_${selectedPool}`].methods.earned(account).call(),
        ),
      ]);
      setStakingBalance(parseFloat(_stakingBalance).toFixed(2));
      setStakedAmount(parseFloat(_stakedAmount).toFixed(2));
      setEarnedBalance(parseFloat(_earnedBalance).toFixed(2));
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong while fetching data.');
    }

    setIsLoading(false);
  }, [account, isConnectedToAllowedNetwork, selectedPool]);

  const fetchCommonData = useCallback(async () => {
    if (!(await isConnectedToAllowedNetwork())) return resetCommonData();

    try {
      const contracts = Contracts.instances;

      setIsLoading(true);
      const _stakingPeriod =
        +(await contracts[`StakingRewards_${selectedPool}`].methods.rewardsDuration().call()) / 86400;
      const _rewardDate =
        +(await contracts[`StakingRewards_${selectedPool}`].methods.periodFinish().call()) * 1000;
      const _stakingTill =
        +(await contracts[`StakingRewards_${selectedPool}`].methods.stakingTill().call()) * 1000;
      const _stakingCap = Web3.instance.utils.fromWei(
        await contracts[`StakingRewards_${selectedPool}`].methods.stakingCap().call(),
      );
      const _totalStaking = Web3.instance.utils.fromWei(
        await contracts[`StakingRewards_${selectedPool}`].methods.totalSupply().call(),
      );

      setStakingCap(parseInt(_stakingCap));
      setRewardDate(_rewardDate);
      setStakingPeriod(_stakingPeriod.toFixed(2));
      setStakingTill(_stakingTill);
      setTotalStaking(+_totalStaking);
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong while fetching data.');
    }

    setIsLoading(false);
  }, [isConnectedToAllowedNetwork, selectedPool]);

  const getStakingAggregate = async () => {
    if (!(await isConnectedToAllowedNetwork())) return;
    var totalStaked = 0;
    const contracts = Contracts.instances;
    const pools = ['90_Days', '180_Days', '365_Days'];
    for (const pool of pools) {
      const _totalStaking = Web3.instance.utils.fromWei(
        await contracts[`StakingRewards_${pool as PoolDuration}`].methods.totalSupply().call(),
      );
      totalStaked += parseFloat(_totalStaking);
    }

    const newAccount = '0xEFba0cDb081d851034c6dF2579affF21515f58b3';
    var totalAmountStakedByNewAcc = 0;

    for (const pool of pools) {
      const _totalAmountStakedNew = Web3.instance.utils.fromWei(
        await contracts[`StakingRewards_${pool as PoolDuration}`].methods.balanceOf(newAccount).call(),
      );
      totalAmountStakedByNewAcc += parseFloat(_totalAmountStakedNew);
    }

    setStakingAggregate(totalStaked - totalAmountStakedByNewAcc);
  };

  useEffect(() => {
    getStakingAggregate();
  }, [refresh.triggerValue]);

  useEffect(() => {
    fetchCommonData();
    account ? fetchUserData() : resetUserData();
  }, [account, fetchCommonData, fetchUserData, refresh.triggerValue]);

  if (!account) {
    return <MetamaskAuth />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <LaunchDateHeader />
      <HeaderTransparent />
      <BuyToken />
      <div className={classes.content}>
        <h2>STAKE YOUR CHMB TOKENS</h2>

        <PoolTabs selectedPool={selectedPool} setSelectedPool={setSelectedPool} />

        <Card
          stakingBalance={stakingBalance}
          stakedAmount={stakedAmount}
          earnedBalance={earnedBalance}
          stakingPeriod={stakingPeriod}
          rewardDate={rewardDate as string | number}
          selectedPool={selectedPool}
          setSelectedPool={setSelectedPool}
          stakingMode={isStakingMode}
          stakingCap={stakingCap}
          stakingWindow={isStakingWindow}
          totalStaking={totalStaking}
        />

        <div className={classes.totalStaked}>
          {isLoading ? <Spinner /> : <>Total Staked: {numberWithCommas(stakingAggregate.toFixed(2))} CHMB</>}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
