import styles from './BuyToken.module.scss';
import KucoinBtn from 'assets/kucoin-button.png';
import PancakeSwapBtn from 'assets/pancake-button.png';
import OrionBtn from 'assets/orion-button.png';

const BuyToken = () => {
  return (
    <div className={styles.main}>
      <h2>BUY CHMB TOKEN</h2>

      <div className={styles.btnCntnr}>
        <a href="https://trade.kucoin.com/trade/CHMB-USDT" target="_blank" rel="noopener noreferrer">
          <img src={KucoinBtn} alt="Buy on Kucoin" />
        </a>
        <a
          href="https://pancakeswap.finance/swap?inputCurrency=0x5492ef6aeeba1a3896357359ef039a8b11621b45"
          target="_blank"
          rel="noopener noreferrer">
          <img src={PancakeSwapBtn} alt="Buy on Pancake Swap" />
        </a>
        <a href="https://trade.orionprotocol.io/trade/CHMB-BUSD" target="_blank" rel="noopener noreferrer">
          <img src={OrionBtn} alt="Buy on Orion" />
        </a>
      </div>
    </div>
  );
};

export default BuyToken;
