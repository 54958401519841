import { memo, useEffect } from 'react';
import { PoolDuration } from 'App';
import clsx from 'clsx';
import Contracts from 'helpers/contracts';
import { DaysBetween } from 'helpers/utils';
import React from 'react';
import classes from './Tabs.module.scss';
import { useWallet } from 'contexts/Wallet';

const PoolTabs: React.FC<{
  selectedPool: PoolDuration;
  setSelectedPool: (pool: PoolDuration) => void;
}> = ({ setSelectedPool, selectedPool }) => {
  const [remainingDays, setRemainingDays] = React.useState({} as Record<PoolDuration, number>);

  const { refresh } = useWallet();

  const getDaysLeft = async (pool: PoolDuration) => {
    const contracts = Contracts.instances;

    const _rewardDate = +(await contracts[`StakingRewards_${pool}`].methods.periodFinish().call()) * 1000;

    const daysBetween = DaysBetween(Date.now(), _rewardDate as number);
    const daysLeft = daysBetween > 0 ? daysBetween : 0;

    return daysLeft;
  };

  useEffect(() => {
    ['90_Days', '180_Days', '365_Days'].forEach(async (pool) => {
      const _daysLeft = await getDaysLeft(pool as PoolDuration);
      setRemainingDays((prev) => ({ ...prev, [pool]: _daysLeft }));
    });
  }, [refresh.triggerValue]);

  return (
    <div className={classes.main}>
      {[
        { pool: '90_Days', text: '3 Months', apr: '100%', days: 90, num: 1 },
        { pool: '180_Days', text: '6 Months', apr: '150%', days: 180, num: 2 },
        { pool: '365_Days', text: '12 Months', apr: '200%', days: 365, num: 3 },
      ].map((pool, index) => {
        return (
          <div
            key={index}
            className={clsx(classes.tab, {
              [classes.selected]: selectedPool === pool.pool,
            })}
            onClick={() => setSelectedPool(pool.pool as PoolDuration)}>
            {remainingDays[pool.pool as PoolDuration] || '0'} Days
            <span className={classes.apr} style={{ fontSize: '13px' }}>
              Option : {pool.num}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default memo(PoolTabs);
