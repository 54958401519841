import { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import useForceUpdate from 'hooks/useForceUpdate';
import usePersistentToast from 'hooks/usePersistentToast';

import Web3 from 'helpers/web3';
import { allowedChains, ContractAddress } from 'config';
import Contracts from 'helpers/contracts';

const { ethereum } = window as any;

const contracts = Contracts.instances;

const WalletContext = createContext({
  account: '',
  disconnect: () => {},
  connect: () => {},
  isConnectedToAllowedNetwork: async () => false,
  claimToken: async () => {},
  purchaseComplete: false,
  approveComplete: false,
  quantityRemaining: 3000,
  isPurchasing: false,
  setApproveComplete: (approve: false) => {},
  setPurchaseComplete: (purchase: false) => {},
  refresh: {
    rerender: () => {},
    triggerValue: 0,
  },
  setTokenAmount: (amount: number) => {},
});

export const useWallet = () => useContext(WalletContext);

const isConnectedToAllowedNetwork = async () => {
  const chainId = parseInt(await ethereum?.request({ method: 'eth_chainId' }));
  return !(allowedChains.length > 0 && !allowedChains.find((chain) => chain.id === chainId));
};

const WalletContextProvider = ({ children }: any) => {
  const [account, setAccount] = useState<string>('');
  const [purchaseComplete, setPurchaseComplete] = useState<boolean>(false);
  const [approveComplete, setApproveComplete] = useState<boolean>(false);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [quantityRemaining, setQuantityRemaining] = useState(3000);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const forceUpdate = useForceUpdate();

  const persistentSwitchChainToast = usePersistentToast(
    'Please connect to one of the supported chains',
    'error',
  );

  const persistentWeb3BrowserToast = usePersistentToast(
    'Ensure you are using a Web3 enabled browser',
    'error',
  );

  const transactionInProgressToast = usePersistentToast('Transaction in progress', 'info');

  const checkIsUserWhitelisted = async () => {
    return await contracts.LootBox.methods.isWhitelisted(account).call();
  };

  const getQuantityValue = async () => {
    try {
      const occupiedSpot = await contracts.LootBox.methods.occupiedSlots().call();
      const totalSupply = await contracts.LootBox.methods.totalSlots().call();
      setQuantityRemaining(Number(totalSupply) - Number(occupiedSpot));
    } catch (error) {
      console.log(error);
    }
  };

  function timeout(delay: number) {
    toast.info('You are not whitelisted');
    return new Promise((res) => setTimeout(res, delay));
  }

  function timeout2(delay: number) {
    toast.info('Please select minimum token value to 1');
    return new Promise((res) => setTimeout(res, delay));
  }

  const claimToken = async () => {
    try {
      setIsPurchasing(true);
      transactionInProgressToast.trigger();
      setPurchaseComplete(false);
      setApproveComplete(false);
      const res = await checkIsUserWhitelisted();
      if (tokenAmount <= 0) {
        await timeout2(1500);

        transactionInProgressToast.dismiss();
        setIsPurchasing(false);

        return;
      }
      if (res) {
        const slotPrice = await contracts.LootBox.methods.slotPrice().call();
        if (slotPrice) {
          const web3 = Web3.instance;
          const approvalAmount = web3.utils.toBN(slotPrice).mul(web3.utils.toBN(tokenAmount));
          // Approve
          await contracts.Token.methods
            .approve(ContractAddress.Lootbox, approvalAmount)
            .send({ from: account });
          setApproveComplete(true);
          // Estimate gas - to get error messages if any
          await contracts.LootBox.methods.claimSlotBatch(tokenAmount).estimateGas({ from: account });
          // Purchase/Claim
          await contracts.LootBox.methods.claimSlotBatch(tokenAmount).send({ from: account });
          setPurchaseComplete(true);
        }
      } else {
        await timeout(1000);
      }
    } catch (error) {
      console.log(error);
    }

    setIsPurchasing(false);
    transactionInProgressToast.dismiss();
  };

  const connect = async () => {
    if (!Web3.isEnabledInBrowser()) return persistentWeb3BrowserToast.trigger();

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      setAccount(accounts[0]);
    } catch (e: any) {
      switch (e.code) {
        case 4001:
          toast.info('Please connect to Metamask');
          break;
        case -32002:
          toast.info('Please open Metamask');
          break;
      }
    }
  };

  const disconnect = () => {
    setAccount('');
    forceUpdate.rerender();
  };

  const refresh = async () => {
    forceUpdate.rerender();
    if (await isConnectedToAllowedNetwork()) return persistentSwitchChainToast.dismiss();
    persistentSwitchChainToast.trigger();
  };

  useEffect(() => {
    getQuantityValue();
  }, [setQuantityRemaining, quantityRemaining]);

  useEffect(() => {
    const init = async () => {
      if (!Web3.isEnabledInBrowser()) return persistentWeb3BrowserToast.trigger();
      // if (!(await isConnectedToAllowedNetwork())) persistentSwitchChainToast.trigger();

      ethereum.on('chainChanged', refresh);
      ethereum.on('accountsChanged', (accounts: string[]) => setAccount(accounts[0] || ''));
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    account,
    connect,
    disconnect,
    isConnectedToAllowedNetwork,
    claimToken,
    purchaseComplete,
    approveComplete,
    setPurchaseComplete,
    setApproveComplete,
    setTokenAmount,
    quantityRemaining,
    isPurchasing,
    refresh: { rerender: refresh, triggerValue: forceUpdate.triggerValue },
  };

  return <WalletContext.Provider value={value}>{children}</WalletContext.Provider>;
};

export default WalletContextProvider;
