// hooks
import useInput from 'hooks/useInput';
import { useWallet } from 'contexts/Wallet';
import useSwitch from 'hooks/useSwitch';

// libraries
import { toast } from 'react-toastify';
import BN from 'bn.js';
import { isMobile } from 'react-device-detect';

// components
import Spinner from '../Spinner';
import ChumbiNFTCrateModal from 'components/ChumbiNFTCrates.module.scss';

// helpers
import Web3 from 'helpers/web3';
import Contracts from 'helpers/contracts';
import { getDate, numberWithCommas } from 'helpers/utils';

// styles
import classes from 'components/Card/Card.module.scss';

// config
import { APP_CONFIG, ContractAddress } from 'config';
import { validateAmount } from 'helpers/validate';
import { PoolDuration } from 'App';

// assets
import crateImg from '../../assets/crate-img-new.png';
import { useEffect, useState } from 'react';

type Props = {
  stakingBalance: string;
  stakedAmount: string;
  earnedBalance: string;
  stakingPeriod: string;
  rewardDate: string | number;
  stakingMode: boolean;
  selectedPool: PoolDuration;
  setSelectedPool: (pool: PoolDuration) => void;
  stakingCap: number;
  stakingWindow: boolean;
  totalStaking: number;
};

const StakeCard = (props: Props) => {
  const {
    stakingBalance,
    stakedAmount,
    earnedBalance,
    stakingPeriod,
    rewardDate,
    setSelectedPool,
    stakingMode,
    selectedPool,
    stakingCap,
    stakingWindow,
    totalStaking,
  } = props;
  const { account, isConnectedToAllowedNetwork, refresh, setPurchaseComplete, setApproveComplete } =
    useWallet();
  const stakingAmount = useInput();
  const stakingInProcess = useSwitch();
  const withdrawalInProcess = useSwitch();

  const [displayExclusiveModal, setDisplayExclusiveModal] = useState(false);

  const onStake = async () => {
    if (!account) {
      return toast.info('Please connect your wallet');
    }

    if (!(await isConnectedToAllowedNetwork())) {
      return toast.info('Please connect to one of the supported chains');
    }

    if (!validateAmount(stakingAmount.value)) {
      return;
    }

    if (parseFloat(stakingAmount.value) > parseFloat(stakingBalance)) {
      return toast.info('Insufficient Balance');
    }

    try {
      const web3 = Web3.instance;
      const _stakingAmount = web3.utils.toWei(stakingAmount.value);
      const contracts = Contracts.instances;

      const totalSupply = await contracts[`StakingRewards_${selectedPool}`].methods.totalSupply().call();
      const stakingCap = await contracts[`StakingRewards_${selectedPool}`].methods.stakingCap().call();

      if (new BN(_stakingAmount).add(new BN(totalSupply)).gt(new BN(stakingCap))) {
        return toast.error('Staking cap exceeded');
      }

      stakingInProcess.true();
      const allowance = await contracts.Token.methods

        .allowance(account, ContractAddress.StakingRewards)
        .call();

      if (new BN(_stakingAmount).gt(new BN(allowance))) {
        await contracts.Token.methods
          .approve(ContractAddress[`StakingRewards_${selectedPool}`], _stakingAmount)
          .send({ from: account });
      }
      if (isMobile) {
        await contracts[`StakingRewards_${selectedPool}`].methods
          .stake(_stakingAmount)
          .send({ from: account, gas: 0x927c0, gasPrice: 0xba43b7400 });
      } else {
        await contracts[`StakingRewards_${selectedPool}`].methods
          .stake(_stakingAmount)
          .send({ from: account });
      }
      toast.success('Staked Successfully');
      stakingAmount.reset();
      refresh.rerender();
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
    stakingInProcess.false();
  };

  const handleExclusiveBtnClick = () => {
    setDisplayExclusiveModal((prev) => !prev);
  };

  const onWithdraw = async () => {
    if (!account) {
      return toast.info('Please connect to your wallet');
    }

    if (!(await isConnectedToAllowedNetwork())) {
      return toast.info('Please connect to one of the supported chains', { theme: 'colored' });
    }

    if (earnedBalance === '0.00') {
      return toast.error('Already withdrawn or did not participate');
    }

    try {
      withdrawalInProcess.true();
      const contracts = Contracts.instances;
      await contracts[`StakingRewards_${selectedPool}`].methods.exit().send({ from: account });
      toast.success('Withdrawal successful');
      refresh.rerender();
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    }
    withdrawalInProcess.false();
  };

  const calculateAPY = () => {
    const _stakingPeriod = Math.ceil(parseFloat(stakingPeriod));
    const apy = (600000000 / _stakingPeriod / totalStaking) * 100 * 365;
    return apy;
  };

  useEffect(() => {
    if (!displayExclusiveModal) {
      setPurchaseComplete(false);
      setApproveComplete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayExclusiveModal]);

  return (
    <div className={classes.card}>
      <div>
        <div className={classes.heading}>My CHMB Staking</div>
        <span className={classes.apr}>APR : {numberWithCommas(calculateAPY().toFixed(2)) || 0}%</span>
        <span
          className={classes.apr}
          style={{ display: 'block', fontSize: '14px', textDecoration: 'underline', color: 'white' }}>
          <a href="https://chumbivalley.com/apr-info" target="_blank" rel="noopener noreferrer">
            APR Information
          </a>
        </span>
        <div className={classes.center}>
          <div className={classes.inputWrapper}>
            {stakingMode ? (
              stakingWindow ? (
                totalStaking >= stakingCap ? (
                  <button disabled={true} className={classes.withdrawButton}>
                    Staking Cap Reached
                  </button>
                ) : (
                  <>
                    <div className={classes.input}>
                      <input
                        type="number"
                        min={0}
                        value={stakingAmount.value}
                        onChange={stakingAmount.set}
                        placeholder="Amount"
                        pattern="[0-9]"
                        disabled={stakingInProcess.value}
                      />
                      <button onClick={onStake} disabled={stakingInProcess.value}>
                        {stakingInProcess.value ? <Spinner /> : 'Stake'}
                      </button>
                      {/* <button className={classes.exclusiveButton} onClick={handleExclusiveBtnClick}>
                        <img className={classes.crateImg} src={crateImg} alt="crate" />
                        Exclusive Staking Offer
                      </button> */}
                    </div>
                  </>
                )
              ) : (
                <button disabled={true} className={classes.withdrawButton}>
                  Staking Window has ended
                </button>
              )
            ) : (
              <>
                <button
                  onClick={onWithdraw}
                  disabled={withdrawalInProcess.value}
                  className={classes.withdrawButton}>
                  {withdrawalInProcess.value ? <Spinner /> : 'Withdraw'}
                </button>
                {/* <button className={classes.exclusiveButton} onClick={handleExclusiveBtnClick}>
                  <img className={classes.crateImg} src={crateImg} alt="crate" />
                  Exclusive Staking Offer
                </button> */}
              </>
            )}

            <div className={classes.balance}>
              Your Total ${APP_CONFIG.STAKING_TOKEN.toUpperCase()}:{' '}
              {numberWithCommas(parseFloat(stakingBalance))}
            </div>
          </div>
        </div>

        <div className={classes.stats}>
          <div className={classes.stat}>
            <span>Currently Staked:</span>
            <strong>
              {numberWithCommas(parseFloat(stakedAmount))} {APP_CONFIG.STAKING_TOKEN}
            </strong>
          </div>
          <div className={classes.stat}>
            Staking Period: <strong>{stakingPeriod} Days</strong>
          </div>
        </div>
        <div className={classes.stats}>
          <div className={classes.stat}>
            Reward Earned:{' '}
            <strong>
              {numberWithCommas(parseFloat(earnedBalance))} {APP_CONFIG.REWARD_TOKEN}
            </strong>
          </div>
          <div className={classes.stat}>
            Reward Date: <strong>{getDate(new Date(rewardDate))}</strong>
          </div>
        </div>

        <div className={classes.note}>
          <strong>Note: </strong>
          The staked token will be locked for the whole duration of the program. Only after the reward date{' '}
          {getDate(new Date(rewardDate))}, you can withdraw the total staked amount of token + the staking
          rewards to your wallet in {APP_CONFIG.REWARD_TOKEN}.
        </div>
      </div>
      <ChumbiNFTCrateModal
        show={displayExclusiveModal}
        setShow={setDisplayExclusiveModal}
        onClose={() => setDisplayExclusiveModal(false)}
        setSelectedPool={setSelectedPool}
      />
    </div>
  );
};

export default StakeCard;
