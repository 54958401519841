import styles from './MessageModal.module.scss';
import { useWallet } from '../../contexts/Wallet';

type Props = {
  show: boolean;
  onClose: () => void;
  setShow: (active: boolean) => void;
  setMonthPool?: (active: string) => void;
  setShowBModal: (active: boolean) => void;
  modalState: string;
};

function MessageModal({ show, onClose, setShow, modalState, setShowBModal, setMonthPool }: Props) {
  const { claimToken, isPurchasing } = useWallet();
  return (
    <>
      {show && modalState === 'info' && (
        <div className={styles.messageModalOuterContainer} onClick={onClose}>
          <div className={styles.messageModalInnerContainer} onClick={(e) => e.stopPropagation()}>
            <h1 className={styles.mainText}>Chumbi Crate NFT Minting</h1>
            <p className={styles.secondText}>
              You will not receive your Chumbi NFT Crate at the time of purchase. Your crate(s) will be minted
              and sent to the Polygon version of your wallet at a later date. Crate opening will then occur
              after the crate NFTs are minted.
            </p>
            <div className={styles.btnDiv}>
              <button
                className={styles.greenBtn}
                onClick={claimToken}
                disabled={isPurchasing}
                style={isPurchasing ? { backgroundColor: 'grey' } : {}}>
                I agree
              </button>
              <button
                className={styles.redBtn}
                onClick={() => setShow(false)}
                disabled={isPurchasing}
                style={isPurchasing ? { backgroundColor: 'grey' } : {}}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {show && modalState === 'nothanks' && (
        <div className={styles.messageModalOuterContainer} onClick={onClose}>
          <div className={styles.messageModalInnerContainer} onClick={(e) => e.stopPropagation()}>
            <h1 className={styles.mainText}>Re-Stake to Support Chumbi Valley!</h1>
            <p className={styles.secondText}>
              Thank you for being a loyal staker! We are working around the clock to expand the Chumbi
              ecosystem and increase utility for CHMB. Show your continued support by re-staking into either
              the 6 month or 12 month pools and earn some great rewards!
            </p>
            <div className={styles.btnDiv}>
              <button
                className={styles.viewPoolBtn}
                onClick={() => {
                  setShowBModal(false);
                  setShow(false);
                  if (setMonthPool) setMonthPool('6');
                }}>
                View 6 Month Pool
              </button>
              <button
                className={styles.viewPoolBtn}
                onClick={() => {
                  setShowBModal(false);
                  setShow(false);
                  if (setMonthPool) setMonthPool('12');
                }}>
                View 12 Month Pool
              </button>
            </div>
            <button
              className={styles.noThanksBtn}
              onClick={() => {
                setShowBModal(false);
                setShow(false);
              }}>
              No Thanks
            </button>
          </div>
        </div>
      )}

      {show && modalState === 'purchase' && (
        <div className={styles.messageModalOuterContainer} onClick={onClose}>
          <div className={styles.messageModalInnerContainer} onClick={(e) => e.stopPropagation()}>
            <h1 className={styles.mainText}>Purchase Successful</h1>
            <p className={styles.secondText}>
              Congrats! Your Chumbi Crate purchase was successful. You will receive your Chumbi Crate NFT(s)
              in the Polygon version of your wallet. Keep an eye on our Twitter for updates on the crate
              minting and opening dates.
            </p>
            <div className={styles.btnDiv}>
              <button className={styles.greenBtn} onClick={() => setShow(false)}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MessageModal;
